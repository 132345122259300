<template>
  <div class="withdraw-detail">
    <b-row>
      <b-col
        cols="24"
        md="12"
      >
        <b-card title="Basic Info">
          <b-col cols="24 pt-0">
            <hr>
          </b-col>
          <div class="basic-content">
            <div>
              <b-card-text>Transaction Id: {{ basicInfo.transaction_id }}</b-card-text>
              <b-card-text>Product name: {{ basicInfo.description }}</b-card-text>
              <b-card-text>Update time: {{ updatedTime }}</b-card-text>
            </div>
            <div>
              <b-card-text>Transfer status:
                {{ basicInfo.transaction_status_text }}</b-card-text>
              <b-card-text>Currency: {{ basicInfo.currency }}</b-card-text>
            </div>
            <div>
              <b-card-text>Amount: {{ basicInfo.amount }}</b-card-text>
              <b-card-text>Create time: {{ createTime }}</b-card-text>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-card title="User Info">
          <b-col cols="24 pt-0">
            <hr>
          </b-col>
          <b-card-text>Name: {{ userInfo.name }}</b-card-text>
          <b-card-text>Country: {{ userInfo.country }}</b-card-text>
          <!-- class="text-primary" -->
          <b-card-text>User ID: {{ userInfo.user_id }}</b-card-text>
          <b-card-text>Document type: {{ userInfo.document_type }}</b-card-text>
          <b-card-text>Document ID: {{ documentId_f }}</b-card-text>
          <b-card-text>Email: {{ userInfo.email }} </b-card-text>
          <b-card-text>Phone number: {{ phone_f }}</b-card-text>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-card title="Beneficiary account">
          <b-col cols="24 pt-0">
            <hr>
          </b-col>
          <b-card-text>Name: {{ beneficiaryAccount.name }}</b-card-text>
          <b-card-text>Country: {{ beneficiaryAccount.country }}</b-card-text>
          <!-- class="text-primary" -->
          <b-card-text>User ID: {{ beneficiaryAccount.user_id }}</b-card-text>
          <b-card-text>Document type: {{ beneficiaryAccount.documentType }}</b-card-text>
          <b-card-text>Document ID: {{ documentId_b_f }}</b-card-text>
          <b-card-text>Email: {{ beneficiaryAccount.email }}</b-card-text>
          <b-card-text>Phone number: {{ phone_b_f }}</b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import {
  getQueryString,
  updateDate,
  formatDocumentId,
  formatPhoneNumber,
  showToast,
} from '@/libs/utils'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    vSelect,
    BFormInput,
    BButton,
  },
  props: {},
  data() {
    return {
      fields: ['status', 'created_at', 'message'],
      selectStatusOption: ['Fail', 'Created', 'Processing', 'Success'],
      operationForm: {
        selectStatus: null,
        remark: null,
        password: null,
      },
      basicInfo: {},
      userInfo: {},
      beneficiaryAccount: {},
    }
  },
  computed: {
    createTime() {
      return updateDate(this.basicInfo.create_time, getQueryString('timezone'))
    },
    updatedTime() {
      return updateDate(this.basicInfo.update_time, getQueryString('timezone'))
    },
    documentId_f() {
      return formatDocumentId(
        this.userInfo.document_id,
        this.basicInfo.currency,
      )
    },
    phone_f() {
      return formatPhoneNumber(this.userInfo.phone)
    },
    documentId_b_f() {
      return formatDocumentId(
        this.beneficiaryAccount.document_id,
        this.basicInfo.currency,
      )
    },
    phone_b_f() {
      return formatPhoneNumber(this.beneficiaryAccount.phone)
    },
    pixkey() {
      if (this.beneficiaryAccount.pixType == 'PHONE') {
        return formatPhoneNumber(this.beneficiaryAccount.pixVal)
      }
      if (
        this.beneficiaryAccount.pixType == 'CPF'
        || this.beneficiaryAccount.pixType == 'CNPJ'
      ) {
        return formatDocumentId(this.beneficiaryAccount.pixVal)
      }
      return this.beneficiaryAccount.pixVal
    },
  },
  mounted() {
    this.initTransferDetail()
  },
  methods: {
    initTransferDetail() {
      this.$showLoading()
      const { id } = this.$route.query
      this.$http
        .get(`/api/v1/transfer/details?transaction_id=${id}`)
        .then(response => {
          this.$hideLoading()
          if (response.data.code == 200) {
            const { basic_info, user_info, beneficiary_account } = response.data.data
            this.basicInfo = basic_info || {}
            this.userInfo = user_info || {}
            this.beneficiaryAccount = beneficiary_account || {}
          } else {
            showToast(this, 'warning', response.data.message)
          }
        })
        .catch(error => {
          this.$hideLoading()
          showToast(this, 'warning', error.message)
        })
    },
  },
  setup() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.withdraw-detail {
  width: 80%;
  padding: 4rem 0;
  margin: 0 auto;
  min-height: 400px;
  .basic-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  ::v-deep .history-table {
    word-break: break-all;
    word-wrap: break-all;
    .table th,
    .table td {
      padding: 10px !important;
    }
    .table tr td:nth-of-type(1) {
      width: 8.4rem;
    }
    .table tr td:nth-of-type(2) {
      width: 12.7rem;
    }
  }
  .per-page-selector {
    width: 90px;
  }
  .userinfo-form label {
    width: 100px;
    justify-content: left;
  }
  .invoice-filter-select {
    min-width: 280px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  .d-inline-block {
    width: 280px;
  }
}
</style>
